<template>
    <div>
    
        <b-navbar sticky class="app-navbar-profile">
           <div class="app-navbar-header">
               <b-row class="align-items-center mb-3 ml-1 mr-1">
                   <b-col cols="8"> 
                       <div class="app-local-name">{{trans('settings',280)}}</div>
                   </b-col>        
               </b-row> 
           </div>
       </b-navbar>

       <div>       
           <b-row class="ml-3 mr-3">
               <b-col md="6" sm="12">
                   <b-card no-body class="app-local-settings" >
                        <div class="m-3">
                            <b-row class="app-card-header mt-2 mb-3">
                                <b-col start>
                                    <div class="app-card-tittle">
                                        <div>{{trans('cp-send-weekly-reminder',290)}} </div>    
                                    </div>
                                </b-col>
                            </b-row>
                            <app-row-left-label>
                                <app-radio-group :disabled="loading" v-model="weekly_reminder" :options="question_options" />
                            </app-row-left-label>
                        </div>
                    </b-card>
               </b-col>
           </b-row>

       </div>

       <app-button @click="saveSettings" :loading="loading" class="ml-5 mt-3" type="primary">
            {{trans('save',282)}}
        </app-button>
   </div>
</template>

<script>
import axios from "@axios";
import {  ValidationObserver } from "vee-validate";

export default {
    
    components: {
        //ValidationObserver,
    },

    created(){
        this.getData();
    },

    data(){
        return{
            loading: false,
            question_options: [
                { value: 1, text: this.trans('yes-option',280) },
                { value: 0, text: this.trans('no-option',280) },
            ],
            weekly_reminder: null
        }
    },

    methods: {

        getData: function() {
            this.loading = true;
            
            axios
                .post("clients/settings/getData")
                .then((res) => {
                    this.weekly_reminder = res.data.weekly_reminder;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`)
                })
                .finally(() =>{
                    this.loading = false;
                });
            
        },

        saveSettings() {
            
            this.loading = true;

            axios
                .post("clients/settings/change", {
                    weekly_reminder: this.weekly_reminder,
                })
                .then((res) => {
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },
    }

}
</script>

<style scoped>
.app-local-name{
    font-size:24px;
    font-weight: 600;
}

.app-navbar-header{
    width:100%;
}
.app-local-settings{
    min-height: 200px;
}
</style>